
import * as Api from "@/types/graphql";
import Card from "@/components/Card.vue";
import Btn from "@/components/Btn.vue";
import ServicesSelector from "@/components/ServicesSelector.vue";
import SelectedServices from "@/components/SelectedServices.vue";
import Modal from "@/components/Modal.vue";
import { Component, Vue } from "vue-property-decorator";
import { Action, Mutation, State } from "vuex-class";
import { State as StateStore } from "../store";
import _ from "lodash";
import { ServiceStatus } from "@/utils/status";

@Component({
  components: { Card, Btn, ServicesSelector, SelectedServices, Modal },
})
export default class YourServicesView extends Vue {
  @State((state: StateStore) => state.auth.user) user: Api.User;
  @State((state: StateStore) => state.data.company) company: Api.Company;
  @State((state: StateStore) => state.data.selectedServices)
  selectedServices: Api.Service[];
  @Action getUser;
  @Action fetchCompany;
  @Action updateCompanyServices;
  @Mutation showError;
  @Mutation resetSelectedServices;
  @Mutation showSuccess;

  showContent = false;
  showConfirmationModal = null;

  async mounted() {
    if (this.user.company_guid != null) {
      await this.fetchCompany(this.user.company_guid);
      this.showContent = true;
    } else {
      this.showError("Nessuna azienda collegata all'utente corrente");
      this.showContent = false;
    }
  }

  showConfModal() {
    if (_.isEmpty(this.selectedServices)) {
      this.showError("Selezionare almeno un servizio");
    } else {
      this.showConfirmationModal = true;
    }
  }

  async save() {
    const updated = await this.updateCompanyServices({
      companies: [this.company.guid],
      services: _.map(this.selectedServices, "guid"),
      status: ServiceStatus.REQUEST,
    });
    this.showConfirmationModal = null;
    if (updated) {
      await this.fetchCompany(this.user.company_guid);
      this.resetSelectedServices();
      this.showSuccess("Servizi correttamente richiesti");
    } else {
      this.showError("Errore durante richiesta associazione servizi");
    }
  }
}
